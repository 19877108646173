import { call, put } from 'redux-saga/effects';
import {
  changeStatusAfterUpdate,
  generateReportFailure,
  generateReportSuccess,
} from '../actions';
import { ApiError } from 'store/api/types';
import { GenerateReportRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import {
  CheckEntityStatusResponse,
  GenerateReportResponse,
  checkEntityStatus,
  generateReport,
} from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { DataStatus, VatReportData } from '../models';
import { DataLogStatus } from 'store/app/enums';
import { MessageType } from 'store/api/enums';
import { push } from 'connected-react-router';
import routes from 'routes';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* generateReportSaga(action: GenerateReportRequestAction) {
  try {
    let err: ApiError | null;
    const { vatGenerateRequestData, totalTransactions } = action.payload;

    const statusCheckResponse: CheckEntityStatusResponse = yield call(
      checkEntityStatus,
      vatGenerateRequestData.connectionId
    );
    if (statusCheckResponse.data.status !== DataStatus.IDLE) {
      const message: MessageData = {
        title: 'Server busy',
        description:
          'We are currently updating the data from the provider. Please try again later',
        type: MessageStates.WARNING,
      };
      yield put(turnMessageOn(message));
      yield put(generateReportFailure());
      //} else if (statusCheckResponse.data.status === DataStatus.IDLE) {
    } else {
      let pg = 0;
      let totalPgs = 0;
      let newTotals = 0;
      let noDataMessage = '';
      do {
        pg++;

        const response: GenerateReportResponse = yield call(
          generateReport,
          vatGenerateRequestData,
          pg
        );

        err = checkResponse(response?.responseMessage);
        if (err) throw err;
        if (
          !response.data &&
          response.responseMessage.messageType === MessageType.WARNING
        ) {
          noDataMessage = response.responseMessage.message;
        } else {
          const vatReportData: VatReportData = response.data;
          totalPgs = vatReportData?.totalPages ? vatReportData?.totalPages : 0;
          const lastPage = pg === totalPgs || totalPgs === 0;
          yield put(generateReportSuccess(vatReportData, lastPage));
          newTotals =
            newTotals +
            vatReportData.journals.length +
            vatReportData.exceptions.length;
        }
      } while (pg < totalPgs);

      if (noDataMessage) {
        const message: MessageData = {
          title: 'Server Message',
          description: noDataMessage,
          type: MessageStates.WARNING,
        };
        yield put(turnMessageOn(message));
        yield put(generateReportFailure());
        yield put(push(routes.main));
      } else {
        const difference = newTotals - totalTransactions;
        if (difference > 0 && totalTransactions !== 0) {
          const message: MessageData = {
            title: 'System Message',
            description: `There are ${difference} new transactions. Save before continuing or revert data`,
            type: MessageStates.INFO,
          };
          yield put(turnMessageOn(message));
          yield put(changeStatusAfterUpdate(DataLogStatus.NOT_SAVED));
        }
      }
    }
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(generateReportFailure());
  }
}
