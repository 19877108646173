import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { saveMappingRequest } from 'store/returns/actions';
import {
  getReturnData,
  getReturnLoading,
  getReturnPreLoading,
  getReturnTaxRatesLoading,
} from 'store/returns/selectors';
import { MappingForm } from 'components/MappingForm';
import { useState } from 'react';
import { MappingData } from 'store/returns/models';
import {
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import moment from 'moment';
import { calculateNextPeriod } from 'utils/calculate-next-period';
import { Form, Spin } from 'antd';
import { Section } from 'store/returns/models/returnData';
import { MappingTaxRate } from 'store/returns/models/mappingTaxRate';
import {
  turnDrawerOn,
  turnMessageOn,
  updateMappingData,
} from 'store/app/actions';
import { MessageStates } from 'containers/MessageBox';
import { DrawerData, MessageData } from 'store/app/types';
import { useTranslation } from 'react-i18next';
import { getMappingData } from 'store/app/selectors';
import { DrawerType } from 'store/app/enums';
import { ChooseReturn } from 'components/MappingForm/choose-return';
import { push } from 'connected-react-router';
import routes from 'routes';
import { ChooseTemplate } from 'components/MappingForm/choose-template';
import { getCommonCountries } from 'store/common/selectors';

export interface StepOneData {
  entity?: string;
  country?: string;
  typeName?: string;
  firstPeriod?: string;
  firstDueDate?: string;
}

export type TaxRateOption = {
  label: string;
  value: string;
};

export interface SelectedRate {
  value: string;
  location: string[];
  cashAccounting: boolean;
}

export interface TemplateMapping {
  mappingId: string;
  organisationName: string;
  createdDate: string;
  country?: string;
}

export const AddReturnPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parameters: any = useParams();
  const conId = parameters.connectionId;
  const mappingId = parameters.mappingId;
  const mappings = useSelector(getOrgMappings);
  const organisations = useSelector(getOrgOrganisations);
  const preLoading = useSelector(getReturnPreLoading);
  const taxRatesLoading = useSelector(getReturnTaxRatesLoading);
  const loading = useSelector(getReturnLoading);
  const returnData = useSelector(getReturnData);
  const mappingData = useSelector(getMappingData);
  const countries = useSelector(getCommonCountries);
  const [form2] = Form.useForm();
  const [stepOneData, setStepOneData] = useState<StepOneData>({});
  //const [endDate, setEndDate] = useState<Date>(new Date('1900-01-01'));
  const editMode: boolean = !!mappingId;
  const [selectedReturnType, setSelectedReturnType] = useState<string>();
  const [taxRateOptions, setTaxRateOptions] = useState<TaxRateOption[]>();
  const [outOfScopeOptions, setOutOfScopeOptions] = useState<TaxRateOption[]>();
  const [sections, setSections] = useState<Section[]>();
  const [cashAccounting, setCashAccounting] = useState<boolean>(false);
  const [outOfScopeCode, setOutOfScopeCode] = useState<string>('OOS');
  const [selectedRates, setSelectedRates] = useState<SelectedRate[]>([]);
  const [selectedRatesCopy, setSelectedRatesCopy] = useState<SelectedRate[]>(
    []
  );

  //const [current, setCurrent] = useState(0);
  const [templateMappings, setTemplateMappings] = useState<TemplateMapping[]>(
    []
  );
  const editInitialData = () => {
    if (conId) {
      const drawerData: DrawerData = {
        title: 'Add a Tax Return',
        type: DrawerType.ADD_TAX_RETURN,
        component: <ChooseReturn editMode={true} />,
      };
      dispatch(turnDrawerOn(drawerData));
    }
  };

  const setMappingData = (data: MappingData) => {
    dispatch(updateMappingData(data));
  };
  //const onSetCurrent = (val: number) => setCurrent(val);
  // const updateMapData = (data: MappingData) => {
  //   setMappingData(data);
  // };

  // const onSetEndDate = (endDate: Date) => {
  //   setEndDate(endDate);
  // };

  const updateSelectedRates = (mappedData: SelectedRate[]) => {
    setSelectedRates(mappedData);
    setSelectedRatesCopy([...mappedData]);
  };

  const saveMappingData = (mData: MappingData) => {
    //let mapId = editMode ? mappingId : undefined;
    //const mapData = { ...mData, mappingId: mapId };

    dispatch(saveMappingRequest(conId, mData));
  };

  // const toggleCashAccounting = (value: boolean) => {
  //   setUseCashAccounting(value);
  // };

  const openTemplateWindow = () => {
    const drawerData: DrawerData = {
      title: 'Load from another Entity',
      type: DrawerType.LOAD_FROM_OTHER_ENTITY,
      component: (
        <ChooseTemplate
          templateMappings={templateMappings}
          loadExistingMapping={loadExistingMapping}
        />
      ),
    };
    dispatch(turnDrawerOn(drawerData));
  };

  const setOptions = (
    trOptions: TaxRateOption[],
    oosOptions: TaxRateOption[]
  ) => {
    setTaxRateOptions(trOptions);
    setOutOfScopeOptions(oosOptions);
  };

  const prepareTaxRateOptions = () => {
    const opts: TaxRateOption[] = [];
    returnData?.taxRates?.map((tr) =>
      opts.push({
        value: tr.id,
        label: `${tr.name} [${tr.taxRate.toString()}%]`,
      })
    );
    return opts;
  };

  const sortSelectionsByReturnType = (returnType: string) => {
    setSelectedReturnType(returnType);
    const hasCA = returnData?.returnTypesEdit?.find(
      (rt) => rt.id === returnType
    )?.cashAccountingEnabled;

    hasCA ? setCashAccounting(true) : setCashAccounting(false);
    const sec = returnData?.sections?.filter((s) => {
      return (
        s.returnTypeId === returnType ||
        (hasCA && s.name === s.subsections[0].code)
      );
    });

    sec?.sort((a, b) => a.sortOrder - b.sortOrder);
    sec && setSections(sec);
    const ooc = sec?.find((s) => s.subsections[0].outOfScope)?.subsections[0]
      .code;
    ooc && setOutOfScopeCode(ooc);
  };

  const addUnmappedToOutOfScope = () => {
    if (
      returnData?.taxRates &&
      selectedRates.length === returnData?.taxRates?.length
    ) {
      const message: MessageData = {
        title: t('common.attention') + '!',
        description: t('returns.mapping.all-rates-mapped'),
        type: MessageStates.INFO,
      };
      dispatch(turnMessageOn(message));
    } else {
      const md: SelectedRate[] = [];
      const oosValue: string[] = [];
      if (returnData?.taxRates) {
        returnData?.taxRates.map((rt) => {
          const nIndex = selectedRates.findIndex((sr) => sr.value === rt.id);
          let mdRow: SelectedRate;
          if (nIndex === -1) {
            mdRow = {
              value: rt.id,
              location: [outOfScopeCode],
              cashAccounting: false,
            };
            oosValue.push(rt.id);
          } else {
            mdRow = { ...selectedRates[nIndex] };
            if (mdRow.location[0] === outOfScopeCode) oosValue.push(rt.id);
          }

          md.push(mdRow);
        });

        updateSelectedRates(md);
        handleTaxRangeChange(oosValue, true, '');
        form2.setFieldsValue({
          ['outOfScope']: oosValue,
        });
      }
    }
  };

  const handleCashAccounting = (rateId: string) => {
    const tempSelRates: SelectedRate[] = [...selectedRatesCopy];
    const nIndex = tempSelRates.findIndex((tsr) => tsr.value === rateId);
    if (nIndex > -1) {
      tempSelRates[nIndex] = {
        ...tempSelRates[nIndex],
        cashAccounting: !tempSelRates[nIndex]?.cashAccounting,
      };
      setSelectedRatesCopy(tempSelRates);
    }
  };

  const fillCashAccounting = (value: boolean) => {
    const tempSelRates: SelectedRate[] = [];

    selectedRatesCopy.forEach((sr) => {
      tempSelRates.push({ ...sr, cashAccounting: value });
    });
    setSelectedRatesCopy(tempSelRates);
  };

  const saveCashAccounting = () => {
    setSelectedRates(selectedRatesCopy);
  };

  const loadCashAccounting = () => {
    setSelectedRatesCopy([...selectedRates]);
  };

  const handleTaxRangeChange = (
    rateIds: string[],
    outOfScope: Boolean,
    code: string
  ) => {
    const opts: TaxRateOption[] = prepareTaxRateOptions();
    if (outOfScope) {
      const tempSelRates: SelectedRate[] = [...selectedRates];
      rateIds.map((r) => {
        const rateIndex = opts.findIndex((o) => o.value === r);
        rateIndex > -1 && opts.splice(rateIndex, 1);
        const srIndex = tempSelRates.findIndex((tsr) => tsr.value === r);
        if (srIndex === -1)
          tempSelRates.push({
            value: r,
            location: [outOfScopeCode],
            cashAccounting: false,
          });
        else
          tempSelRates.splice(srIndex, 1, {
            value: r,
            location: [outOfScopeCode],
            cashAccounting: false,
          });
      });
      const tempSR = [...tempSelRates];
      tempSR.map((t, idx) => {
        const locIndex = t.location.findIndex((l) => l === outOfScopeCode);
        if (locIndex > -1 && rateIds.findIndex((ri) => t.value === ri) === -1) {
          tempSelRates[idx].location = [];
        }
      });
      const tmpRates = tempSelRates.filter((tsr) => tsr.location.length !== 0);
      setSelectedRates(tmpRates);
      setSelectedRatesCopy([...tmpRates]);
      setTaxRateOptions(opts);
    } else {
      const tempSelRates: SelectedRate[] = [...selectedRates];
      rateIds.map((ri) => {
        const selIndex = selectedRates.findIndex((sr) => sr.value === ri);
        if (selIndex === -1)
          tempSelRates.push({
            value: ri,
            location: [code],
            cashAccounting: false,
          });
        else {
          const codeIndex = selectedRates[selIndex].location.findIndex(
            (l) => l === code
          );
          if (codeIndex === -1) selectedRates[selIndex].location.push(code);
        }
      });
      const tempSR = [...tempSelRates];
      tempSR.map((t, idx) => {
        const locIndex = t.location.findIndex((l) => l === code);
        if (locIndex > -1 && rateIds.findIndex((ri) => t.value === ri) === -1) {
          tempSelRates[idx].location.splice(locIndex, 1);
        }
      });
      const finalSelRates = tempSelRates.filter(
        (tsr) => tsr.location.length > 0
      );

      finalSelRates.map((fsr) => {
        const rateIndex = opts.findIndex((o) => o.value === fsr.value);
        if (rateIndex > -1 && fsr.location[0] !== outOfScopeCode)
          opts.splice(rateIndex, 1);
      });
      setSelectedRates(finalSelRates);
      setSelectedRatesCopy([...finalSelRates]);
      setOutOfScopeOptions(opts);
    }
  };

  const loadExistingMapping = (mapId: string, isTemplate: boolean = false) => {
    const savingMapId = isTemplate ? mappingId : mapId;
    const selMap = mappings?.find((m) => m.mappingId === mapId);

    const savingPeriodFromDate = isTemplate
      ? mappingData?.periodFromDate
      : selMap?.periodFromDate;
    const savingReturnDueDate = isTemplate
      ? mappingData?.returnDueDate
      : selMap?.returnDueDate;
    const savingReturnFrequency = isTemplate
      ? mappingData?.returnFrequency
      : selMap?.returnFrequency;
    let jsonMapping;
    if (selMap?.jsonMapping) {
      try {
        const parsedMapping = JSON.parse(selMap.jsonMapping);

        jsonMapping = parsedMapping?.mapping;
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    if (
      returnData?.taxRates &&
      returnData.taxRates.length < jsonMapping.length
    ) {
      const toDelete = jsonMapping.filter(
        (jm) =>
          returnData.taxRates?.findIndex((tr) => tr.taxType === jm.taxType) ===
          -1
      );
      toDelete.map((td) => {
        jsonMapping.splice(
          jsonMapping.findIndex((jm) => jm.taxType === td.taxType),
          1
        );
      });
    }
    const mapData = {
      mappingId: savingMapId,
      templateMappingId: mapId,
      typeId: returnData?.returnTypesEdit?.find(
        (rt) => rt.id === selMap?.returnTypeId
      )?.id,
      countryId: returnData?.returnTypesEdit?.find(
        (rt) => rt.id === selMap?.returnTypeId
      )?.countryId,
      mappedCashAccounting: selMap?.cashAccounting,
      periodFromDate: moment(savingPeriodFromDate).format('YYYY-MM-DD'),
      returnDueDate: moment(savingReturnDueDate).format('YYYY-MM-DD'),
      returnFrequency: savingReturnFrequency,
      jsonMapping,
    };
    setMappingData(mapData);
  };

  const resetForm = () => {
    const opts: TaxRateOption[] = prepareTaxRateOptions();
    const jsonMapping: MappingTaxRate[] = [];
    const mapData: MappingData = { ...mappingData, jsonMapping };
    updateSelectedRates([]);
    setMappingData(mapData);
    form2.resetFields();
    setTaxRateOptions(opts);
    setOutOfScopeOptions(opts);
  };

  React.useEffect(() => {
    if (!preLoading) {
      const retTypes = returnData?.returnTypesEdit;
      if (editMode && retTypes && retTypes.length > 0) {
        loadExistingMapping(mappingId);
      }
    }
  }, [preLoading, mappings]);

  React.useEffect(() => {
    if (mappingData && mappingData.mappingId) {
      setSelectedReturnType(mappingData.typeId);
    }
    let entity: any = '';
    let country: any = '';
    let typeName: any = '';
    let firstPeriod: any = '';
    let firstDueDate: string = '';
    if (mappingData) {
      if (mappingData.typeId) {
        console.log('Mapping Data', mappingData);
        const curType = returnData?.returnTypesEdit?.find(
          (rt) => rt.id === mappingData?.typeId
        );
        typeName = curType?.name;
        country = curType?.countryName;
      }
      if (mappingData.periodFromDate && mappingData.returnFrequency) {
        firstPeriod = calculateNextPeriod(
          mappingData.periodFromDate,
          mappingData.returnFrequency
        )[0];
        if (mappingData.returnDueDate) firstDueDate = mappingData.returnDueDate;
      }
      if (organisations && conId) {
        entity = organisations.find(
          (o) => o.connectionId === conId
        )?.friendlyName;
      }

      setStepOneData({ entity, country, typeName, firstPeriod, firstDueDate });
    }
  }, [mappingData]);

  React.useEffect(() => {
    if (returnData?.taxRates) {
      if (!editMode) {
        const opts: TaxRateOption[] = prepareTaxRateOptions();
        setTaxRateOptions(opts);
        setOutOfScopeOptions(opts);
      }
      mappingData?.typeId && sortSelectionsByReturnType(mappingData?.typeId);
    }
  }, [returnData]);

  React.useEffect(() => {
    if (!loading && !returnData && !mappingData?.typeId)
      dispatch(push(routes.main));
  }, []);

  const loadTemplateMappings = () => {
    if (mappings) {
      const otherMaps = mappings.filter((m) => {
        return (
          m.mappingId !== mappingId && m.returnTypeId === selectedReturnType
        );
      });

      const tmplMappings: TemplateMapping[] = [];
      otherMaps.map((om) => {
        const orName = organisations?.find(
          (o) => o.uuid === om.organisationId
        )?.friendlyName;
        const createdDate = moment(om.createdDate).format('DD/MM/YYYY');
        const organisationName = orName ? orName : '';
        const mappingId = om.mappingId;
        const countryIso = organisations?.find(
          (o) => o.uuid === om.organisationId
        )?.country;
        const country = countries.find((c) => c.iso === countryIso)?.name;
        tmplMappings.push({
          createdDate,
          organisationName,
          mappingId,
          country,
        });
      });

      setTemplateMappings(tmplMappings);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Add a return | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for adding a return" />
      </Helmet>
      <Spin spinning={taxRatesLoading || loading || !sections} size="large">
        {!sections ||
          (sections.length != 0 && (
            <MappingForm
              preLoading={preLoading}
              taxRatesLoading={taxRatesLoading}
              loading={loading}
              returnData={returnData}
              mappingData={mappingData}
              saveMappingData={saveMappingData}
              stepOneData={stepOneData}
              form2={form2}
              editMode={editMode}
              selectedRates={selectedRates}
              selectedRatesCopy={selectedRatesCopy}
              outOfScopeCode={outOfScopeCode}
              cashAccounting={cashAccounting}
              handleCashAccounting={handleCashAccounting}
              addUnmappedToOutOfScope={addUnmappedToOutOfScope}
              updateSelectedRates={updateSelectedRates}
              sections={sections}
              taxRateOptions={taxRateOptions}
              outOfScopeOptions={outOfScopeOptions}
              handleTaxRangeChange={handleTaxRangeChange}
              setOptions={setOptions}
              prepareTaxRateOptions={prepareTaxRateOptions}
              hasTemplates={templateMappings.length > 0}
              loadTemplateMappings={loadTemplateMappings}
              openTemplateWindow={openTemplateWindow}
              resetForm={resetForm}
              editInitialData={editInitialData}
              saveCashAccounting={saveCashAccounting}
              fillCashAccounting={fillCashAccounting}
              loadCashAccounting={loadCashAccounting}
            />
          ))}
      </Spin>
    </React.Fragment>
  );
};
