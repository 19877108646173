import { call, delay, put } from 'redux-saga/effects';
import { ConnectCin7EntityRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import {
  connectCin7EntityFailure,
  connectCin7EntitySuccess,
  fetchOrganisationsRequest,
} from '../actions';
import { connectCin7Entity } from '../api';
import { push } from 'connected-react-router';
import routes from 'routes';
import { fetchViesInfoRequest } from 'store/vat-checker/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* connectCin7EntitySaga(action: ConnectCin7EntityRequestAction) {
  try {
    let err: ApiError | null;

    const { key, cin7accountId, orgName } = action.payload;
    const response: ApiResponse = yield call(
      connectCin7Entity,
      key,
      cin7accountId,
      orgName
    );
    //const orgUrl: string = response.data.url;

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(fetchOrganisationsRequest());
    yield delay(3000);
    yield put(fetchViesInfoRequest());
    const message: MessageData = {
      title: `Entity connected`,
      description: 'Cin7 Entity succesfully connected.',
      type: MessageStates.INFO,
    };
    yield put(turnMessageOn(message));
    yield put(connectCin7EntitySuccess());
    yield put(push(routes.vies.qualifiedEntities));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(connectCin7EntityFailure());
  }
}
