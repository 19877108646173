import { call, delay, put, select } from 'redux-saga/effects';
import { ApiError, ApiResponse } from 'store/api/types';
import { ActivateViesRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { activateVies } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { activateViesFailure, activateViesSuccess } from '../actions';

import { getAuthUser } from 'store/auth/selectors';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* activateViesSaga(action: ActivateViesRequestAction) {
  const currentUser = yield select(getAuthUser);
  try {
    let err: ApiError | null;
    const { connectionId, entityName } = action.payload;

    const response: ApiResponse = yield call(activateVies, connectionId);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success',
      description: `${entityName} activated successfully.`, //response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(activateViesSuccess(connectionId));
    localStorage.removeItem(`vies-infos-${currentUser.userUuid}`);
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(activateViesFailure());
  }
}
