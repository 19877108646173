import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MappingData, ReturnData } from 'store/returns/models';
import { Col, Layout, Row } from 'antd';
import { MapTaxRates } from './map-tax-rates';
import { Section, TaxRate } from 'store/returns/models/returnData';
import { MappingTaxRate } from 'store/returns/models/mappingTaxRate';
import { SidePanel } from './side-panel';
import { SelectedRate, StepOneData, TaxRateOption } from 'pages/returns';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { useDispatch } from 'react-redux';
import { turnMessageOn } from 'store/app/actions';

interface AddMappingFormProps {
  preLoading: boolean;
  taxRatesLoading: boolean;
  loading: boolean;
  returnData?: ReturnData;
  mappingData?: MappingData;
  saveMappingData: (mappingData: MappingData) => void;
  stepOneData: StepOneData;
  form2: any;
  editMode: boolean;
  selectedRates: SelectedRate[];
  selectedRatesCopy: SelectedRate[];
  outOfScopeCode: string;
  cashAccounting: boolean;
  handleCashAccounting: (rateId: string) => void;
  addUnmappedToOutOfScope: () => void;
  updateSelectedRates: (mappedData: SelectedRate[]) => void;
  sections?: Section[];
  taxRateOptions?: TaxRateOption[];
  outOfScopeOptions?: TaxRateOption[];
  handleTaxRangeChange: (
    rateIds: string[],
    outOfScope: boolean,
    code: string
  ) => void;
  setOptions: (trOptions: TaxRateOption[], oosOptions: TaxRateOption[]) => void;
  prepareTaxRateOptions: () => TaxRateOption[];
  hasTemplates: boolean;
  loadTemplateMappings: () => void;
  openTemplateWindow: () => void;
  resetForm: () => void;
  editInitialData: () => void;
  saveCashAccounting: () => void;
  fillCashAccounting: (value: boolean) => void;
  loadCashAccounting: () => void;
}

export const mapTaxRatesStyle: React.CSSProperties = {
  backgroundColor: 'white',
  padding: '50px 30px 0 40px',
};

export const MappingForm: React.FC<AddMappingFormProps> = ({
  preLoading,
  taxRatesLoading,
  loading,
  returnData,
  mappingData,
  saveMappingData,
  stepOneData,
  form2,
  editMode,
  selectedRates,
  selectedRatesCopy,
  outOfScopeCode,
  cashAccounting,
  handleCashAccounting,
  addUnmappedToOutOfScope,
  updateSelectedRates,
  sections,
  taxRateOptions,
  outOfScopeOptions,
  handleTaxRangeChange,
  setOptions,
  prepareTaxRateOptions,
  hasTemplates,
  loadTemplateMappings,
  openTemplateWindow,
  resetForm,
  editInitialData,
  saveCashAccounting,
  fillCashAccounting,
  loadCashAccounting,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const saveMapping = (mappingData: MappingData) => {
    saveMappingData(mappingData);
  };
  const [useCashAccounting, setUseCashAccounting] = useState<boolean>(false);
  const toggleCA = (value: boolean) => setUseCashAccounting(value);
  const onSubmit = () => {
    let passed = false;
    if (
      !returnData?.taxRates ||
      selectedRates.length !== returnData.taxRates.length
    ) {
      const message: MessageData = {
        title: t('common.problem-saving') + '!',
        description: t('returns.mapping.rates-must-be-mapped'),
        type: MessageStates.WARNING,
      };
      dispatch(turnMessageOn(message));
    } else {
      const jsonMapping: MappingTaxRate[] = [];
      let mappedCashAccounting = false;
      selectedRates.map((sr) => {
        const rate: TaxRate | undefined = returnData?.taxRates?.find(
          (tr) => tr.id === sr.value
        );
        if (rate) {
          let codes: string | undefined = '';

          if (sr.cashAccounting) {
            mappedCashAccounting = true;
            codes =
              ',' +
              returnData?.sections?.find(
                (s) => s.name === s.subsections[0].code
              )?.subsections[0].code;
          }
          sr.location.map((l) => {
            codes = `${codes},${l}`;
          });
          const taxCodeName = rate?.name;
          const taxRate = rate?.taxRate.toFixed(2);
          const taxType = rate?.taxType;
          codes = codes?.substring(1);
          jsonMapping.push({ taxCodeName, taxRate, taxType, codes });
        }
      });

      const md: MappingData = {
        ...mappingData,
        mappedCashAccounting,
        jsonMapping,
      };

      passed = true;
      saveMapping(md);
    }

    return passed;
  };

  useEffect(() => {
    !loading && loadTemplateMappings();
  }, [editMode, loading]);

  if (taxRatesLoading || loading) return null;
  return (
    <Layout style={{ background: '#F3F7F8' }}>
      <Row justify={'space-between'} style={{ paddingRight: 450 }}>
        <Col span={24} style={{ background: '#FFFFFF' }}>
          <Row justify={'space-between'}>
            <Col span={24}>
              <Row justify={'center'}>
                <Col span={24}>
                  {!preLoading && (
                    <>
                      <div style={mapTaxRatesStyle}>
                        <MapTaxRates
                          sections={sections}
                          taxRateOptions={taxRateOptions}
                          outOfScopeOptions={outOfScopeOptions}
                          handleTaxRangeChange={handleTaxRangeChange}
                          form={form2}
                          mappingData={mappingData}
                          taxRates={returnData?.taxRates}
                          updateSelectedRates={updateSelectedRates}
                          setOptions={setOptions}
                          prepareTaxRateOptions={prepareTaxRateOptions}
                          onSubmit={onSubmit}
                          resetForm={resetForm}
                          addUnmapped={addUnmappedToOutOfScope}
                          useCashAccounting={useCashAccounting}
                        />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <div
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          right: 0,
          maxWidth: 450,
          width: 450,
        }}
      >
        <SidePanel
          selectedRates={selectedRates}
          selectedRatesCopy={selectedRatesCopy}
          taxRates={returnData?.taxRates}
          //taxRatesLoading={taxRatesLoading}
          //loading={loading}
          outOfScopeCode={outOfScopeCode}
          handleCashAccounting={handleCashAccounting}
          hasCashAccounting={cashAccounting}
          //updateSelectedRates={updateSelectedRates}
          stepOneData={stepOneData}
          hasTemplates={hasTemplates}
          openTemplateWindow={openTemplateWindow}
          editInitialData={editInitialData}
          saveCashAccounting={saveCashAccounting}
          fillCashAccounting={fillCashAccounting}
          loadCashAccounting={loadCashAccounting}
          useCashAccounting={useCashAccounting}
          toggleCA={toggleCA}
        />
      </div>
    </Layout>
  );
};
