import { Popover, Space, Spin, Typography } from 'antd';
import {
  ContextButton,
  IconButton,
  IconContextButton,
  ScopeButton,
} from 'components/Custom/buttons';
import moment from 'moment';
import { DashboardContainer, GrayItalicSpan } from 'components/Custom/spans';
import { ScopeTable } from 'components/Custom/table';
import React, { useEffect, useState } from 'react';
import { ReactComponent as UserIcon } from 'assets/images/icons/userIcon.svg';
import { ReactComponent as StarIcon } from 'assets/images/icons/starIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/warningIcon.svg';
import { ReactComponent as ExclamationIcon } from 'assets/images/icons/exclamationIcon.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/images/icons/checkCircleIcon.svg';
import { ReactComponent as RefreshIcon } from 'assets/images/icons/refreshIcon.svg';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as HistoryIcon } from 'assets/images/icons/historyIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/downloadIcon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eyeIcon.svg';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ContactType } from 'store/app/enums';
import { Contact, ViesInfo } from 'store/vat-checker/models';
import { VIESContactStatistic, ViesStatus } from 'store/app/enums';
import { getProviderLink, getVIESProviderLink } from 'utils/get-provider-link';
import { StatusGetter } from 'utils/status-getter';
import { Link } from 'react-router-dom';
import { Organisation } from 'store/organisation/models';

interface CustomersAndSuppliersProps {
  loading: boolean;
  contacts?: Contact[];
  organisation?: Organisation;
  openDownloadCertificateModal: (record: Contact, invoice?: string) => void;
  openConfirmWindow: () => void;
  checkContact: (contactId: string) => void;
  getHistoryLogs: (record: Contact) => void;
  viesInfo?: ViesInfo;
  onRefreshContacts: (connectionId: string) => void;
  onRefreshContact: (contactId: string, connectionId: string) => void;
}

const { Text } = Typography;

export const CustomersAndSuppliers: React.FC<CustomersAndSuppliersProps> = ({
  loading,
  contacts,
  organisation,
  openDownloadCertificateModal,
  openConfirmWindow,
  checkContact,
  getHistoryLogs,
  viesInfo,
  onRefreshContacts,
  onRefreshContact,
}) => {
  const { t } = useTranslation();
  const [selectedContanier, setSelectedContanier] = useState('');
  const [dataSource, setDataSource] = useState(contacts);
  const [updateDueDateFilter, setUpdateDueDateFilter] = useState(false);
  const [updateStatusFilter, setUpdateStatusFilter] = useState(false);

  useEffect(() => {
    setDataSource(contacts);
  }, [contacts]);

  const filterContacts = (key) => {
    setSelectedContanier(key);
    switch (key) {
      case VIESContactStatistic.Customers:
        setDataSource(
          contacts?.filter(
            (item) =>
              item.contactType &&
              (ContactType[item.contactType] == ContactType.CUSTOMER ||
                ContactType[item.contactType] == ContactType.BOTH)
          )
        );
        setUpdateDueDateFilter(false);
        setUpdateStatusFilter(false);
        break;
      case VIESContactStatistic.Suppliers:
        setDataSource(
          contacts?.filter(
            (item) =>
              item.contactType &&
              (ContactType[item.contactType] == ContactType.SUPPLIER ||
                ContactType[item.contactType] == ContactType.BOTH)
          )
        );
        setUpdateDueDateFilter(true);
        setUpdateStatusFilter(false);
        break;
      case VIESContactStatistic.ContactsErrors:
        setDataSource(
          contacts?.filter(
            (item) => item.viesStatus == ViesStatus.ERROR.toUpperCase()
          )
        );
        setUpdateDueDateFilter(false);
        setUpdateStatusFilter(false);
        break;
      case VIESContactStatistic.StatusMissing:
        setDataSource(contacts?.filter((item) => item.viesStatus == 'EMPTY'));
        setUpdateDueDateFilter(false);
        setUpdateStatusFilter(true);
        break;
    }
  };

  const calcContactsType = (type) => {
    return contacts?.filter(
      (item) =>
        item.contactType &&
        (ContactType[item.contactType] == ContactType[type] ||
          ContactType[item.contactType] == ContactType.BOTH)
    )?.length;
  };

  const calcContactsStatus = (status) => {
    return contacts?.filter((item) => item.viesStatus == status.toUpperCase())
      ?.length;
  };

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  const renderCustomizeSection = () => {
    return (
      <Space>
        <GrayItalicSpan color="#879494">
          {viesInfo?.lastViesCheckDate &&
            `Last check: ${moment(viesInfo?.lastViesCheckDate).fromNow()}`}
        </GrayItalicSpan>
        <IconButton //@ts-ignore
          width="107px"
          height="30px"
          fontSize="12px"
          lineheight="12px"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
          }}
          onClick={() => openConfirmWindow()}
          icon={<CheckCircleIcon width={'16px'} height={'16px'} />}
          type="default"
          loading={loading}
        >
          {t('vat-checker.check-vat')}
        </IconButton>
        <IconButton
          loading={loading} //@ts-ignore
          width="225px"
          height="30px"
          fontSize="12px"
          lineheight="12px"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
          }}
          onClick={() => {
            viesInfo && onRefreshContacts(viesInfo?.connectionId);
          }}
          icon={
            <RefreshIcon
              width={'18px'}
              height={'18px'}
              style={{
                filter:
                  'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
              }}
            />
          }
          type="default"
        >
          {t('vat-checker.refresh-customers-suppliers')}
        </IconButton>
      </Space>
    );
  };

  const renderContact = (content: string, record: Contact) => {
    const to = `/vat-checker/contact-details/connection/${organisation?.connectionId}/contact/${record.contactUuid}`;
    return (
      <Space size={'small'} style={{ minWidth: '140px' }}>
        <a
          href={getVIESProviderLink(
            record.providerName?.toLowerCase(),
            record.providerName?.toLowerCase() == 'xero'
              ? organisation?.shortCode
              : record?.contactType &&
                  ContactType[record?.contactType].toLowerCase(),
            record.providerContactId
          )}
          target="_blank"
          rel="noreferrer"
          style={{ height: '32px', display: 'block' }}
        >
          <img
            src={
              record?.providerName
                ? require(`shared/assets/images/${record.providerName.toLowerCase()}_small.png`)
                    .default
                : ''
            }
            width={25}
            style={{ paddingTop: '4px' }}
          />
        </a>
        <Link to={to}>
          <Text
            className="underline-link"
            style={{ minWidth: 150, maxWidth: 150 }} //@ts-ignore
            ellipsis={{ rows: 1, tooltip: true }}
          >
            {content}
          </Text>
        </Link>
      </Space>
    );
  };

  const renderContextMenu = (record: Contact) => {
    const to = `/vat-checker/contact-details/connection/${organisation?.connectionId}/contact/${record.contactUuid}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            icon={
              <CheckCircleIcon
                width={'16px'}
                height={'16px'}
                style={{
                  filter:
                    'brightness(0) saturate(100%) invert(13%) sepia(44%) saturate(720%) hue-rotate(131deg) brightness(95%) contrast(100%)',
                }}
                loading={loading}
              />
            }
            onClick={() => {
              checkContact(record.contactUuid);
            }}
          >
            {t('vat-checker.check-vat')}
          </IconContextButton>
          <IconContextButton
            loading={loading}
            type="text"
            icon={<RefreshIcon width={'18px'} height={'18px'} />}
            onClick={() => {
              viesInfo &&
                onRefreshContact(record.contactUuid, viesInfo?.connectionId);
            }}
          >
            {t('vat-checker.refresh-contact-data')}
          </IconContextButton>
          <IconContextButton
            type="text"
            icon={<HistoryIcon width={'18px'} height={'18px'} />}
            onClick={() => {
              getHistoryLogs(record);
            }}
          >
            {t('vat-checker.history-log')}
          </IconContextButton>
          {record.lastValidCheck && (
            <IconContextButton
              type="text"
              icon={<DownloadIcon width={'18px'} height={'18px'} />}
              onClick={() => {
                const lastViesCheck = record.lastValidCheck?.viesCheckedDate;
                const contactToSend = { ...record, lastViesCheck } as Contact;

                openDownloadCertificateModal(
                  contactToSend,
                  record.lastValidCheck?.invoiceNumber
                    ? record.lastValidCheck?.invoiceNumber
                    : ''
                );
              }}
            >
              {t('vat-checker.download-last-certificate')}
            </IconContextButton>
          )}
          <IconContextButton
            type="text"
            icon={<EyeIcon width={'18px'} height={'22px'} />}
          >
            <Link style={{ textDecoration: 'none' }} to={to}>
              {t('vat-checker.view-contact')}
            </Link>
          </IconContextButton>
        </Space>
      </div>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      //ellipsis: true,
      render: renderContact /* (content: string) => (
        <Text
          style={{ minWidth: 400, maxWidth: 500 }}
          //ellipsis={{ rows: 1, tooltip: true }}
        >
          {record.providerName}-{content}
        </Text>
      ) */,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: renderSortIcon,
    },
    {
      title: 'VAT No',
      dataIndex: 'taxNumber',
      key: 'taxNumber',
      sorter: (a, b) => a.taxNumber?.localeCompare(b.taxNumber),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: (a, b) => a.country?.localeCompare(b.country),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Type',
      key: 'contactType',
      render: (_, record: Contact) => (
        <>{record.contactType ? ContactType[record.contactType] : '-'}</>
      ),
    },
    {
      title: 'Last Certificate',
      render: (_content: string, record: Contact) => {
        if (!record.lastValidCheck) return <span>-</span>;
        else
          return (
            <Space>
              <span>
                {record &&
                  moment(record.lastValidCheck.viesCheckedDate).format(
                    'DD/MM/YYYY'
                  )}
              </span>
              <IconButton
                onClick={() => {
                  const lastViesCheck = record.lastValidCheck?.viesCheckedDate;
                  const contactToSend = { ...record, lastViesCheck } as Contact;

                  openDownloadCertificateModal(
                    contactToSend,
                    record.lastValidCheck?.invoiceNumber
                      ? record.lastValidCheck?.invoiceNumber
                      : ''
                  );
                }}
                icon={
                  <DownloadIcon
                    width={'18px'}
                    height={'18px'}
                    style={{
                      filter:
                        'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
                    }}
                  />
                }
                type="default"
              ></IconButton>
            </Space>
          );
      },
    },
    {
      title: 'Last Check',
      dataIndex: 'lastViesCheck',
      key: 'lastViesCheck',
      render: (_content: string, record: Contact) => {
        return (
          <Space>
            <span>
              {record && moment(record.lastViesCheck).format('DD/MM/YYYY')}
            </span>
            <IconButton
              onClick={() => checkContact(record.contactUuid)}
              icon={
                <CheckCircleIcon
                  width={'16px'}
                  height={'16px'}
                  style={{
                    filter:
                      'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
                  }}
                />
              }
              type="default"
            ></IconButton>
          </Space>
        );
      },
      sorter: (a, b) =>
        new Date(a.lastViesCheck).valueOf() - new Date(b.lastViesCheck).valueOf(), //a.lastViesChack?.localeCompare(b.lastViesChack),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Status',
      dataIndex: 'viesStatus',
      key: 'viesStatus',
      render: (content) =>
        content ? <StatusGetter status={ViesStatus[content]} noDraft /> : null,
      sorter: (a, b) => a.viesStatus.localeCompare(b.viesStatus),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Actions',
      render: (_, record: Contact) => (
        <Space>
          <ScopeButton //@ts-ignore
            width="98px"
            height="25px"
            fontSize="12px"
            lineheight="12px"
            type="primary"
            onClick={() => getHistoryLogs(record)}
          >
            {t('vat-checker.history-log')}
          </ScopeButton>

          <Popover
            placement="topLeft"
            arrow={false}
            trigger="click"
            content={renderContextMenu(record)}
            title={''}
            overlayStyle={{ zIndex: 999 }}
          >
            <ContextButton type="text">
              <EllipseIcon
                style={{
                  height: '10px',
                }}
              />
            </ContextButton>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        padding: '40px',
        paddingTop: '10px',
        maxWidth: 1600,
        minWidth: 1320,
        margin: 'auto',
      }}
    >
      <Spin
        spinning={loading || !organisation}
        size="large"
        style={{ width: '100%', height: '100%' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',
          }}
        >
          <DashboardContainer
            className={
              selectedContanier == VIESContactStatistic.Customers
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() => filterContacts('customers')}
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <UserIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.customers')}
              </Text>
              <Text className="container-number">
                {calcContactsType('CUSTOMER')}
              </Text>
            </Space>
          </DashboardContainer>
          <DashboardContainer
            className={
              selectedContanier == VIESContactStatistic.Suppliers
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() => filterContacts('suppliers')}
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <StarIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.suppliers')}
              </Text>
              <Text className="container-number">
                {calcContactsType('SUPPLIER')}
              </Text>
            </Space>
          </DashboardContainer>
          <DashboardContainer
            className={
              selectedContanier == VIESContactStatistic.ContactsErrors
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() => filterContacts('contacts-errors')}
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <WarningIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.contacts-errors')}
              </Text>
              <Text className="container-number">
                {calcContactsStatus(ViesStatus.ERROR)}
              </Text>
            </Space>
          </DashboardContainer>
          <DashboardContainer
            className={
              selectedContanier == VIESContactStatistic.StatusMissing
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() => filterContacts('status-missing')}
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <ExclamationIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.status-missing')}
              </Text>
              <Text className="container-number">
                {calcContactsStatus('Empty')}
              </Text>
            </Space>
          </DashboardContainer>
        </div>
        {
          //@ts-ignore
          <ScopeTable
            filters={['name', 'viesStatus', 'country']}
            mainLocaleKey="vat-checker"
            originalDataSource={contacts}
            tableDataSource={dataSource}
            columns={columns}
            pagination={true}
            rowsPerPage={10}
            renderRightComponent={renderCustomizeSection()}
            enableColumnsCustomization={false}
            setSelectedContanier={setSelectedContanier}
            haveBorder
          />
        }
      </Spin>
    </div>
  );
};
